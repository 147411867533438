<!--  -->
<template>
    <div class=''>
        <el-row :gutter="20">
            <h3>选择记录</h3>
            <el-col :span="11">
                <el-table ref="staffTable" v-loading="listLoading" :row-key="getRowKey" :key="tableKey" :data="staffList"
                    border fit highlight-current-row @selection-change="handleStaffChange">
                    <el-table-column type="selection" :reserve-selection="true" width="55"></el-table-column>
                    <el-table-column type="index" align="center">
                    </el-table-column>
                    <el-table-column prop="name" label="项目名称" align="center">
                    </el-table-column>
                    <el-table-column prop="createTime" label="记录时间" align="center">
                    </el-table-column>
                    <el-table-column prop="read" label="操作" align="center">
                        <template slot-scope="scope">
                            <el-button @click="handleClick(scope.row)" type="text" size="medium"
                                style="font-size: 16px;">查看记录</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </el-col>
            <el-col :span="2" style="text-align:center;">
                <el-button @click="addStaff" type="primary" :disabled="!staffData.length" icon="el-icon-arrow-right"
                    circle></el-button>
                <br>
                <el-button @click="removeStaff" type="primary" :disabled="!selectedStaffData.length"
                    icon="el-icon-arrow-left" circle style="margin-left: 0;margin-top: 10px;"></el-button>
            </el-col>
            <el-col :span="11">
                <el-table ref="selectedStaffTable" v-loading="listLoading" :row-key="getRowKey" :key="tableKey"
                    :data="selectedStaffList" border fit highlight-current-row
                    @selection-change="handleSelectedStaffChange">
                    <el-table-column type="selection" :reserve-selection="true" width="55"></el-table-column>

                    <el-table-column type="index" align="center">
                    </el-table-column>
                    <el-table-column prop="name" label="项目名称" align="center">
                    </el-table-column>
                    <el-table-column prop="createTime" label="记录时间" align="center">
                    </el-table-column>
                    <el-table-column prop="read" label="操作" align="center">
                        <template slot-scope="scope">
                            <el-button @click="RecordDetail(scope.row)" type="text" size="medium"
                                style="font-size: 16px;">查看记录</el-button>
                        </template>
                    </el-table-column>
                    <el-table-column label="备注" align="center">
                        <template slot-scope="{row}">
                            <el-input placeholder="添加备注" v-model="row.comment">

                            </el-input>
                        </template>
                    </el-table-column>
                </el-table>

                <br>
                <el-button type="primary" @click="generateReport()">生成报告</el-button>
                <br>

                <el-row>
                    <h3>报告预览</h3>
                    <div class="grid-content bg-purple-dark"></div>
                </el-row>

                <br>
                <el-row>
                    <el-button @click="submitReport()">提交</el-button>
                </el-row>
            </el-col>
        </el-row>
    </div>
</template>
    
<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';

export default {
    //import引入的组件需要注入到对象中才能使用
    components: {},
    data() {
        return {
            listLoading: true,
            staffTemp: {
                phone: "",
                nickName: "",
                staffTypeId: "",
                name: '',
                createTime: '',
            },
            staffList: [],
            selectedStaffList: [],
            staffData: [],
            selectedStaffData: [],
            tableKey: 0,
            startTime: '',
            endTime: '',
            header: {},
        }
    },
    methods: {
        // 获取今日日期和30天前日期
        getDate() {
            var today = new Date()
            var formerday = new Date(today);
            formerday.setDate(today.getDate() - 30);
            this.startTime = this.TimeProcess(formerday)
            this.endTime = this.TimeProcess(today)
            console.log(this.startTime)
        },
        // 日期处理
        TimeProcess(time) {
            let y = time.getFullYear();
            let MM = time.getMonth() + 1;
            MM = MM < 10 ? ('0' + MM) : MM;
            let d = time.getDate();
            d = d < 10 ? ('0' + d) : d;
            return y + '-' + MM + '-' + d
        },
        // 从后台获取左边表格的数据
        getStaffList(date1, date2) {
            // ***这里目前的设定是，将每一检测项获取的list都存到staffList里面
            // 静态单导联数据
            this.$http.adornHeader(this.header)
            this.$http({
                url: this.$http.adornUrl("/personal/staticecg/listByTime"),
                method: "get",
                params: this.$http.adornParams({ uid: this.uid, startTime: date1, endTime: date2 }),
            }).then(({ data }) => {
                if (data && data.code === 0) {
                    console.log("看看数据");
                    console.log(data);
                    this.listLoading = false;
                    var singleStaticData = data.staticEcgEntities;
                    // *(替换时间key)如果某些项目关于时间的key不是createTime，可以替换key名
                    // *(替换时间key)let keyMap = { date: 'createTime' }

                    // 添加一个key 作为总的list的项目名
                    let namekey = 'name'
                    let namevalue = '静态单导联'
                    singleStaticData.map(item => {
                        var objs = Object.keys(item).reduce((newData, key) => {
                            // *(替换时间key) let newKey = keyMap[key] || key
                            newData[key] = item[key]
                            newData[namekey] = namevalue
                            return newData

                        }, {})
                        console.log(objs)
                        this.staffList.push(objs)
                    })

                } else {
                    // this.getCaptcha()
                    this.$message.error(data.msg);
                }
            });
            // 动态单导联数据
            this.$http({
                url: this.$http.adornUrl("/personal/dynamicecg/listByTime"),
                method: "get",
                params: this.$http.adornParams({ uid: this.uid, startTime: date1, endTime: date2 }),
            }).then(({ data }) => {
                if (data && data.code === 0) {
                    console.log("看看数据");
                    console.log(data);
                    this.listLoading = false;
                    var singleDynamicData = data.dynamicEcgEntities;
                    // 添加一个key 作为总的list的项目名
                    let namekey = 'name'
                    let namevalue = '动态单导联'
                    singleDynamicData.map(item => {
                        var objs = Object.keys(item).reduce((newData, key) => {
                            newData[key] = item[key]
                            newData[namekey] = namevalue
                            return newData
                        }, {})
                        console.log(objs)
                        this.staffList.push(objs)
                    })

                } else {
                    // this.getCaptcha()
                    this.$message.error(data.msg);
                }
            });
            // 动态八导联数据
            this.$http({
                url: this.$http.adornUrl("/personal/dynamicecg8/listByTime"),
                method: "get",
                params: this.$http.adornParams({ uid: this.uid, startTime: date1, endTime: date2 }),
            }).then(({ data }) => {
                if (data && data.code === 0) {
                    console.log("看看数据");
                    console.log(data);
                    this.listLoading = false;
                    var eightDynamicData = data.dynamicEcgEntities;
                    // 添加一个key 作为总的list的项目名
                    let namekey = 'name'
                    let namevalue = '动态八导联'
                    eightDynamicData.map(item => {
                        var objs = Object.keys(item).reduce((newData, key) => {
                            newData[key] = item[key]
                            newData[namekey] = namevalue
                            return newData
                        }, {})
                        console.log(objs)
                        this.staffList.push(objs)
                    })

                } else {
                    // this.getCaptcha()
                    this.$message.error(data.msg);
                }
            });
        },
        // 将左边表格选择项存入staffData中
        handleStaffChange(rows) {
            this.staffData = [];
            if (rows) {
                rows.forEach(row => {
                    if (row) {
                        this.staffData.push(row);
                    }
                });
            }
        },
        // 左边表格选择项移到右边
        addStaff() {
            setTimeout(() => {
                this.$refs["staffTable"].clearSelection();
                this.$refs["selectedStaffTable"].clearSelection();
            }, 0);
            this.staffData.forEach(item => {
                this.selectedStaffList.push(item);
            });
            for (let i = 0; i < this.staffList.length; i++) {
                for (let j = 0; j < this.staffData.length; j++) {
                    if (
                        this.staffList[i] &&
                        this.staffData[j] &&
                        this.staffList[i].name === this.staffData[j].name
                    ) {
                        this.staffList.splice(i, 1);
                    }
                }
            }
        },
        // 右边表格选择项移到左边
        removeStaff() {
            setTimeout(() => {
                this.$refs["staffTable"].clearSelection();
                this.$refs["selectedStaffTable"].clearSelection();
            }, 0);
            this.selectedStaffData.forEach(item => {
                this.staffList.push(item);
            });
            for (let i = 0; i < this.selectedStaffList.length; i++) {
                for (let j = 0; j < this.selectedStaffData.length; j++) {
                    if (
                        this.selectedStaffList[i] &&
                        this.selectedStaffData[j] &&
                        this.selectedStaffList[i].name === this.selectedStaffData[j].name
                    ) {
                        this.selectedStaffList.splice(i, 1);
                    }
                }
            }
        },
        // 将右边表格选择项存入selectedStaffData中
        handleSelectedStaffChange(rows) {
            this.selectedStaffData = [];
            if (rows) {
                rows.forEach(row => {
                    if (row) {
                        this.selectedStaffData.push(row);
                    }
                });
            }
        },
        getRowKey(row) {
            return row.index;
        },
        // 查看记录详情
        RecordDetail(e) {

        },
        // 生成报告
        generateReport() {

        },
        // 提交
        submitReport() {

        }

    },
    //监听属性 类似于data概念
    computed: {
        crossPlatformStatus: {
            get() { return this.$store.state.module.crossPlatform },
            set(val) { this.$store.commit('module/updateCrossPlatform', val) }
        }
    },
    //监控data中的数据变化
    watch: {},
    //方法集合

    //生命周期 - 创建完成（可以访问当前this实例）
    created() {
        if (this.$store.state.module.crossPlatform) {
            this.header = { "Cross-Platform-Verification": "Cross-Platform-Access" }
        }
        this.crossPlatformStatus = true

        this.uid = this.$route.query.uid
        this.getDate()

        // this.getStaffList(this.startTime, this.endTime)
        this.getStaffList('2022-10-25', '2022-12-26')   // 正常情况应该是上面那句，但是30天内数据太少，自己设了个范围

    },
    //生命周期 - 挂载完成（可以访问DOM元素）
    mounted() {

    },
    beforeCreate() { }, //生命周期 - 创建之前
    beforeMount() { }, //生命周期 - 挂载之前
    beforeUpdate() { }, //生命周期 - 更新之前
    updated() { }, //生命周期 - 更新之后
    beforeDestroy() { }, //生命周期 - 销毁之前
    destroyed() { }, //生命周期 - 销毁完成
    activated() { }, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>

<style scoped>
.bg-purple-dark {
    background: #99a9bf;
}

.grid-content {
    border-radius: 4px;
    min-height: 36px;
}
</style>